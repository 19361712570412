@import "mixins";

.header {
  @include flex(space-between);

  border-bottom: var(--border-width) solid var(--menu-border);
  margin-bottom: (40px - 14px);

  @include mobile {
    @include flex(space-around, flex-start);
    position: fixed;
    bottom: 0;
    width: 100%;
    height: var(--mobile-tab-height);
    margin-bottom: 0;
    border-color: var(--card-bg);
    background: var(--card-bg);
    z-index: 10;
    box-shadow: 0px -10px 20px rgb(0 0 0 / 3%);

    * {
      color: var(--text);
    }
  }
}

/* menu item */
.item {
  color: var(--menu-text);
  fill: var(--menu-text);

  &:hover {
    text-decoration: none;
  }
  @include mobile {
    svg {
      padding: 15px;
      width: 56px;
      height: 56px;
      border: 1px solid var(--card-border);
      border-radius: 12px;
      background: var(--bg);
    }
  }
}

.menu {
  @include mobile {
    margin-top: 64px;
    background: var(--bg);
  }
}

.menuTitle {
  @include flex(space-between);
  font-size: 20px;
  font-weight: 300;
  padding: 0 20px;
  line-height: var(--header-height);
  fill: var(--text);
  a {
    color: var(--text);
  }
  strong {
    font-weight: 700;
  }
  @include desktop {
    display: none;
  }
}

.menuList {
  @include mobile {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 40px 24px;
    background: var(--card-bg);
  }
}

.mobileItem {
  @include flex-column(flex-start);

  padding: 8px;
  color: var(--text);
  fill: var(--text);
  opacity: 0.3;
  font-size: 8px;
  font-weight: var(--bold);
  &.active {
    opacity: 1;
  }

  &:hover {
    text-decoration: none;
  }

  @include desktop {
    display: none;
  }
}

.logo {
  flex: 1;
  display: block;
  font-size: 18px;
  font-weight: 300;
  padding: 0 20px;
  line-height: var(--header-height);

  strong {
    font-weight: 700;
  }

  @include mobile {
    display: none;
  }
}

.link {
  @include flex(flex-start);

  gap: 10px;
  opacity: 0.75;
  padding: 14px 20px;
  transition: opacity var(--transition);

  &:hover {
    opacity: 1;
  }

  &.active {
    opacity: 1;
    font-weight: var(--bold);
  }

  @include mobile {
    @include flex-column(flex-start);
    opacity: 1;

    font-size: var(--font-size-small);
    font-weight: var(--normal);
    color: var(--text);
    fill: var(--text);
  }
}

/* mobile */
.toggle {
  opacity: 0.3;
  &.active {
    opacity: 1;
  }
  @include desktop {
    display: none;
  }
}
