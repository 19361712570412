@import "forms";
@import "mixins";

.wrapper {
  position: relative;
  @include mobile {
    width: auto;
    min-width: 0;
  }
}

.select {
  @include border;
  @include size;
  appearance: none;
  padding-right: 36px;
  width: 100%;
  .flex {
    justify-content: space-between;
    height: 100%;
    svg {
      flex-shrink: 0;
    }
  }
  @include mobile {
    padding: 2px 8px;
  }
}

.before {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  @include mobile {
    padding: 2px 8px;
  }
}

.small {
  border-radius: 4px;
  font-size: var(--font-size-small);
  padding-left: 8px;
  padding-right: 24px;
  height: 24px;
  @include mobile {
    padding: 2px 8px;
  }
}

/* caret */
.caret {
  pointer-events: none;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translate(0, -50%);
}

.small + .caret {
  right: 4px;
}

.label {
  @include truncate;
}
