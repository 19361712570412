.group {
  font-size: 11px;
  overflow: hidden;

  border: none;
  padding: 4px;
  border-radius: 8px;
  background: var(--card-border);
}

.item {
  flex: 1;
  color: var(--text-muted);
  text-align: center;
  height: 24px;

  font-weight: var(--bold);
  border-radius: 6px;
  background: transparent;
}

.active {
  background: var(--card-bg);
  color: var(--text);
  font-weight: var(--bold);
}
