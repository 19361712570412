.contract {
  header {
    flex-direction: column;
    gap: 8px;
  }
}

.main {
  overflow: hidden;
  width: 100%;
}

.link {
  font-weight: var(--normal);
}

.action {
}
