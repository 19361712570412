@import "mixins";

.layout {
  display: grid;

  @include desktop {
    grid-template-columns: 220px 1fr;
    grid-template-rows: auto var(--header-height) 1fr;
    grid-template-areas:
      "banner banner"
      "sidebar header"
      "sidebar main";
  }

  @include mobile {
    grid-template-rows: auto auto 1fr var(--mobile-tab-height);
    grid-template-areas:
      "banner"
      "header"
      "main"
      "sidebar";
  }

  height: 100vh;

  @include mobile {
    &.hiddenMenu {
      grid-template-rows: auto auto 1fr 0 0;
    }
  }
}

.banner {
  grid-area: banner;
  -webkit-app-region: drag;
}

.sidebar {
  grid-area: sidebar;
  grid-template-rows: 1fr auto;

  display: grid;
  background: var(--menu-bg);
  overflow: hidden;

  @include mobile {
    background: var(--card-bg);
  }
}

@mixin border-left {
  @include desktop {
    border-left: var(--border-width) solid var(--card-border);
  }
}

.header {
  @include border-left;

  grid-area: header;
  display: grid;

  background: var(--card-bg);
  gap: 10px;
  padding: 0 var(--main-padding-horizontal);
  position: relative;
  white-space: nowrap;
  @include mobile {
    background: var(--bg);
  }
  .wrapper {
    @include flex(space-between);
    width: 100%;
    height: var(--header-height);
    @include mobile {
      @include flex(space-between);
      margin-top: 10px;
      margin-bottom: -8px;
      h1 {
        font-size: 24px;
      }
    }
    @include desktop {
      h1 {
        display: none;
      }
    }
  }

  @include mobile {
    &.subPage {
      @include flex(flex-start);
      background: var(--card-bg);
      border-bottom: var(--border-width) solid var(--card-border);
      fill: var(--text);
      .wrapper {
        margin-top: 0;
        h1 {
          font-size: 16px;
        }
        button {
          width: 24px;
        }
      }
    }
  }

  -webkit-app-region: drag;

  button {
    -webkit-app-region: no-drag;
  }
}

.actions {
  @include flex;
  gap: 10px;
}

.main {
  @include border-left;

  grid-area: main;
  overflow: auto;
  position: relative; // for progress bar from the page
  @include desktop {
    border-top: var(--border-width) solid var(--card-border);
  }
}

/* menu open */
@include mobile {
  .menu {
    grid-template-rows: auto 1fr;
    grid-template-areas: "banner" "sidebar";

    .header,
    .main {
      display: none;
    }
    .sidebar {
      background: var(--bg);
      section {
        margin-top: 0;
      }
    }
  }
}
