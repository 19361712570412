@import "mixins";

.page {
  background: var(--card-bg);
}
/* token */
.connect {
  padding: 20px;
  svg {
    width: 60px;
    height: 60px;
    fill: var(--text);
  }
  article {
    margin: 0;
  }
  h1 {
    margin-top: 25px;
    margin-bottom: 28px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }

  .detail {
    background: var(--bg-muted);
    border-color: var(--input-border);
  }
}

.screen {
  height: calc(100vh - var(--header-height) - var(--mobile-tab-height));
}
